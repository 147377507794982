import React from 'react';
import animationJson from './success.json';
import Lottie from 'lottie-react';

const Success = () => {
   return (
      <Lottie
         style={{
            width: '25%',
            height: 'auto'
         }}
         animationData={animationJson}
         loop={false}
      />
   );
};

export default Success;
