import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import './assets/styles/_general.scss'
import GlobalProvider from './context/GlobalContext'
import FirebaseProvider from './firebase'
import { persistor, store } from './store'
import Cookie from './cookie'

function responsiveFontSizes(
  { sm, md, lg, xl } = { sm: 12, md: 14, lg: 14, xl: 16 }
) {
  return `
    @media (min-width:600px) {
      html {
        font-size: ${sm}px;
      }
    }
    @media (min-width:1000px) {
      html {
        font-size: ${md}px;
      }
    }
    @media (min-width:1201px) {
      html {
        font-size: ${lg}px;
      }
    }
    @media (min-width:1400px) {
      html {
        font-size: ${xl}px;
      }
    }
  `
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <style>{responsiveFontSizes()}</style>
    <Cookie />
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <Provider store={store}>
      <GlobalProvider>
        <PersistGate loading={null} persistor={persistor}>
          <FirebaseProvider>
            <App />
          </FirebaseProvider>
        </PersistGate>
      </GlobalProvider>
    </Provider>
  </>
)
