import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  onSnapshot,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore'
import { setProjects } from '@/store/projects'
import { useFirebase } from '@/firebase'
import { RootState } from '@/store'
import { useLocation } from 'react-router-dom'

const useSetProjects = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const location = useLocation()
  const path = location.pathname
  const { api, store } = useFirebase()
  useEffect(() => {
    if (!user) return

    const unsub = onSnapshot(api?.listenToProjects(), async (docs: any) => {
      const projIds = docs.docs.map((doc: any) => doc.data().projectID.trim())
      if (projIds.length === 0) {
        dispatch(setProjects([]))
      } else {
        const projects: any[] = []
        for (let i = 0; i < projIds.length; i += 10) {
          const slicedIds = projIds.slice(i, i + 10)
          const projectsRef = collection(store, 'Projects')
          const projectsQuery = query(
            projectsRef,
            where('__name__', 'in', slicedIds)
          )
          const projectsSnapShot = await getDocs(projectsQuery)
          const slicedProjects = projectsSnapShot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          projects.push(...slicedProjects)
        }
        let sortedProjects = projects.sort(
          (a, b) => b?.projectTrack?.createdDate - a?.projectTrack?.createdDate
        )
        dispatch(setProjects(sortedProjects))
      }
    })

    return () => {
      unsub()
    }
  }, [user, path])

  return true
}

export default useSetProjects
