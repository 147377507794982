import {
  ButtonBase,
  createTheme,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Grid,
  ThemeProvider,
  InputLabel,
} from '@mui/material'
import { useFormik } from 'formik'
import { MuiTelInput } from 'mui-tel-input'
import React, { useEffect, useState } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { useSelector } from 'react-redux'
import styles from './checkout.module.scss'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import CardItem from './CardItem/CardItem'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Success from '../../assets/images/images/Payment/success'
import { dateToGoodFormat, getDefaultCountry, notify } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../store'
import CheckIcon from '@mui/icons-material/Check'
import useGetConfig from '../../hooks/useGetConfig'
import { useFirebase } from '@/firebase'
import { Box, Button, Card, Typography } from '@mui/joy'

const Checkout = ({ planName, isSetting, setCheckoutPage, annual }: any) => {
  const navigate = useNavigate()
  const { time } = useGetConfig()

  const { api, store } = useFirebase()
  const defaultCountry: any = getDefaultCountry()
  const [activeStep, setActiveStep] = useState(0)
  const [paymentDetails, setPaymentDetails] = useState<any>()
  const [isRedirected, setIsRedirected] = useState(false)
  const steps = ['Billing Details', 'Payment Method', 'Confirmation']

  const inputStyle = {
    '& .MuiFormLabel-root': {
      fontFamily: 'facto, sans-serif',
      fontWeight: 'bold',
      fontSize: '0.8rem',
    },
    '& .MuiInputBase-root': {
      fontSize: '0.8rem',
      fontWeight: 'bold',
    },
  }

  const plans: any = {
    Enterprise: 89.9,
    Professional: annual ? 950 : 99,
    Basic: 0,
  }

  const { user } = useSelector((state: RootState) => state.auth)

  const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY
  let stripePromise: any

  if (typeof publishableKey === 'string') {
    stripePromise = loadStripe(publishableKey)
    // Use stripePromise
  }
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      address: '',
      country: '',
      city: '',
      state: '',
      phoneNumber: '',
      sendToEmail: 'myEmail',
      email: '',
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.firstName) {
        errors.firstName = 'Required'
      }
      if (!values.lastName) {
        errors.lastName = 'Required'
      }
      if (!values.address) {
        errors.address = 'Required'
      }
      if (!values.country) {
        errors.country = 'Required'
      }
      if (!values.city) {
        errors.city = 'Required'
      }

      if (values.sendToEmail === 'alternativeEmail' && !values.email) {
        errors.email = 'Required'
      }
      return errors
    },
    onSubmit: async (values) => {
      try {
        values = {
          ...values,
          email: values.sendToEmail === 'myEmail' ? user.email : values.email,
        }
        setPaymentDetails({
          name: `${values.firstName} ${values.lastName}`,
          phone: values.phoneNumber,
          email: values.email,
          address: {
            city: values.city,
            country: values.country,
            line1: values.address,
            line2: null,
            postal_code: null,
            state: values.city,
          },
        })
        setActiveStep(1)
      } catch (error) {
        console.log(error)
      } finally {
      }
    },
  })

  useEffect(() => {
    if (activeStep === 2) {
      notify(
        'SUCCESS',
        'Payment Successful! You are being redirected to the homepage'
      )
      api?.updateCustomerSubscriptionPeriod(annual ? 'annual' : 'monthly')
      setIsRedirected(true)
    }
    // eslint-disable-next-line
  }, [activeStep])

  useEffect(() => {
    if (isRedirected) {
      setTimeout(() => {
        navigate('/', { replace: true })
        navigate(0)
      }, 3000)
    }
  }, [isRedirected])

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#fff',
        boxShadow: '5px 5px 15px 0 rgba(0, 0, 0, 0.16)',
        border: 'none',
        borderRadius: '10px',

        flexGrow: 1,

        width: isSetting ? '100%' : '90vw',
        margin: 'auto',
      }}
    >
      <div className={styles.Main}>
        <div style={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        {activeStep === 0 && (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1.5}>
              <Grid item md={6} xs={12}>
                <InputLabel className={styles.MuiFormLabel}>
                  First Name *
                </InputLabel>
                <TextField
                  size="small"
                  fullWidth
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                    style: {
                      fontSize: '.9rem',
                    },
                  }}
                  sx={{
                    mt: 1,
                  }}
                  variant="outlined"
                  type="text"
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel className={styles.MuiFormLabel}>
                  Last Name *
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  name="lastName"
                  onChange={formik.handleChange}
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                    style: {
                      fontSize: '.9rem',
                    },
                  }}
                  sx={{
                    mt: 1,
                  }}
                  value={formik.values.lastName}
                  variant="outlined"
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <InputLabel className={styles.MuiFormLabel}>
                  Address *
                </InputLabel>

                <TextField
                  size="small"
                  fullWidth
                  autoComplete="password"
                  name="address"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      fontSize: '.9rem !important',
                    },
                    mt: 1,
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  variant="outlined"
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <InputLabel className={styles.MuiFormLabel}>
                  Country *
                </InputLabel>

                <CountryDropdown
                  valueType="short"
                  labelType="full"
                  classes={styles.formControl}
                  value={formik.values.country}
                  defaultOptionLabel="Select Country *"
                  name="country"
                  onChange={(val) => formik.setFieldValue('country', val)}
                  priorityOptions={['TR', 'CA', 'US', 'GB']}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <InputLabel className={styles.MuiFormLabel}>City *</InputLabel>

                <RegionDropdown
                  classes={styles.formControl}
                  blankOptionLabel="No country selected *"
                  defaultOptionLabel="Select a city"
                  country={formik.values.country}
                  countryValueType="short"
                  value={formik.values.city}
                  name="city"
                  onChange={(val) => formik.setFieldValue('city', val)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel className={styles.MuiFormLabel}>
                  Phone Number
                </InputLabel>
                <MuiTelInput
                  defaultCountry={defaultCountry ? defaultCountry : 'EE'}
                  required
                  sx={{
                    mt: 1,
                    '&:hover': { borderColor: '#3943b7' },
                    bgcolor: '#fff',
                    '&.Mui-focused': { borderColor: '#3943b7' },
                    '& .MuiButtonBase-root': {
                      '&:focus': {
                        outline: 'none',
                      },
                    },
                    fontFamily: 'facto, sans-serif',
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#1d3557',
                      '&.Mui-focused': {
                        color: '#1d3557',
                      },
                    },

                    '& fieldset': {
                      borderColor: formik.errors.phoneNumber && '#d32f2f',
                    },
                    '& label': {
                      color: formik.errors.phoneNumber && '#d32f2f',
                    },
                    '& input': {
                      fontWeight: 600,
                    },
                  }}
                  fullWidth
                  size="small"
                  // defaultCountry="TR"
                  placeholder="Phone Number *"
                  preferredCountries={['TR']}
                  value={formik.values.phoneNumber}
                  onChange={(val: any) =>
                    formik.setFieldValue('phoneNumber', val)
                  }
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <RadioGroup
                  style={{ justifyContent: 'space-between' }}
                  row
                  value={formik.values.sendToEmail}
                  onChange={(e) =>
                    formik.setFieldValue('sendToEmail', e.target.value)
                  }
                >
                  <FormControlLabel
                    value={'myEmail'}
                    control={<Radio />}
                    label="Send to my account email"
                  />
                  <FormControlLabel
                    value={'alternativeEmail'}
                    control={<Radio />}
                    label="Send to an alternative email"
                  />
                  <TextField
                    className={styles.Input}
                    size="small"
                    type="email"
                    sx={{
                      ...inputStyle,
                      width: '100%',
                      ml: { xs: '0', lg: '60%' },
                    }}
                    required
                    disabled={formik.values.sendToEmail === 'myEmail'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name={'email'}
                    label="Alternative Email"
                    variant="outlined"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '30%',

                  color: 'var(--schemePurple)',
                  borderColor: 'var(--schemePurple)',
                  '&:hover': {
                    borderColor: 'var(--schemePurple)',
                    color: '#fff',
                    backgroundColor: 'var(--schemePurple)',
                  },
                }}
                onClick={() => {
                  setCheckoutPage(false)
                }}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="solid"
                sx={{
                  width: '30%',
                  color: 'white',
                  backgroundColor: 'var(--schemePurple)',
                  '&:hover': {
                    backgroundColor: 'var(--schemePurple)',
                  },
                  ml: 'auto',
                }}
              >
                Next
              </Button>
            </Box>
          </form>
        )}
        {activeStep === 1 && (
          <Elements stripe={stripePromise}>
            <CardItem
              annual={annual}
              planName={planName}
              setActiveStep={setActiveStep}
              paymentDetails={paymentDetails}
            />
          </Elements>
        )}
        {activeStep === 2 && (
          <div className={styles.SuccessMessage}>
            <Success />
            Your payment has been successful
          </div>
        )}
      </div>

      <Card
        sx={{
          width: '30%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          borderRadius: '10px',
          boxShadow: 'none',
          backgroundColor: '#f5f5f7',
          border: 'none',
        }}
      >
        <Typography
          sx={{
            fontWeight: '500',
            color: 'var(--schemeColorDark)',
            borderBottom: '1px solid #b7bfc7',
            paddingBottom: '0.5rem',
            fontSize: '1rem',
          }}
        >
          Summary
        </Typography>
        <Typography
          sx={{
            fontWeight: '500',
            color: 'var(--schemeColorDark)',
            borderBottom: '1px solid #b7bfc7',
            paddingBottom: '0.5rem',
            mt: 1,
            fontSize: '1.2rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {planName}
          <Typography
            sx={{
              fontSize: '0.8rem',
              color: '#bbbbbb',
              fontWeight: '400',
            }}
          >
            For users who want to do more
          </Typography>
        </Typography>
        <Typography
          sx={{
            fontWeight: '500',
            color: 'var(--schemeColorDark)',

            mt: 1,
            fontSize: '1.2rem',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          €{plans[planName]}{' '}
          <Typography
            sx={{
              fontSize: '1rem',
              color: 'var(--schemePurple)',
              fontWeight: '400',
            }}
          >
            {annual ? ' /y' : ' /m'} + Annotation Cost
          </Typography>
        </Typography>
        <Typography
          sx={{
            fontSize: '0.8rem',
            color: '#bbbbbb',
            fontWeight: '400',
            borderBottom: '1px solid #b7bfc7',
            paddingBottom: '0.5rem',
          }}
        >
          Payment is billed {annual ? 'yearly' : 'monthly'}
        </Typography>
        <Typography
          sx={{
            fontSize: '0.8rem',
            color: 'var(--schemeColorDark)',

            display: 'flex',
            alignItems: 'flex-start',
            fontWeight: '400',
          }}
          startDecorator={
            <InfoIcon sx={{ color: '#3943b7' }} fontSize="small" />
          }
        >
          The amount to be paid at the end of the month will include the
          labeling fee in addition to the plan fee.
        </Typography>

        {!isSetting && (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', mt: 'auto', mb: 2 }}
          >
            <Typography
              sx={{
                fontWeight: '500',
                color: 'var(--schemeColorDark)',
                borderBottom: '1px solid #b7bfc7',
                paddingBottom: '0.5rem',
                fontSize: '1rem',
              }}
            >
              Free trial terms
            </Typography>

            <Typography
              sx={{
                fontWeight: '400',
                color: 'var(--schemeColorDark)',
                mt: 1,
                fontSize: '0.8rem',
              }}
            >
              <CheckIcon
                sx={{
                  paddingTop: '5px',
                }}
              />
              Billing starts automatically after your free trial period has
              expired.
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                color: 'var(--schemeColorDark)',
                mt: 1,
                fontSize: '0.8rem',
              }}
            >
              <CheckIcon
                sx={{
                  paddingTop: '5px',
                }}
              />
              If you do not want the invoice to be issued, cancel the membership
              before {dateToGoodFormat(time + 2592000000)}.
            </Typography>
          </Box>
        )}
      </Card>
    </Card>
  )
}

export default Checkout
