import { createSlice } from "@reduxjs/toolkit";

export interface AsyncState {
  loading: number;
  uploading: boolean;
  error: any;
  initalized: boolean;
}

const initialState: AsyncState = {
  loading: 0,
  uploading: false,
  error: null,
  initalized: false,
};

export const asyncSlice = createSlice({
  name: "async",
  initialState,
  reducers: {
    asyncActionStart: (state) => {
      state.loading += 1;
      state.error = null;
    },
    asyncActionFinish: (state) => {
      state.loading = state.loading === 0 ? 0 : state.loading - 1;
    },
    asyncActionUploadingStart: (state) => {
      state.uploading = true;
    },
    asyncActionUploadingFinish: (state) => {
      state.uploading = false;
    },
    asyncActionError: (state, action) => {
      state.error = action.payload;
      state.loading = 0;
    },
    initApp: (state) => {
      state.initalized = true;
    },
  },
});

export const {
  asyncActionStart,
  asyncActionFinish,
  asyncActionUploadingStart,
  asyncActionUploadingFinish,
  asyncActionError,
  initApp,
} = asyncSlice.actions;

export default asyncSlice.reducer;
