import { UploadTaskSnapshot } from 'firebase/storage';
import { removeImage } from '../store/image';
import { Dispatch } from 'redux';
const onStateChanged =
  (
    dispatch: Dispatch,
    updatePercentage: any,
    firebase: any,
    imageName: string
  ) =>
  (snapshot: UploadTaskSnapshot) => {
    if (updatePercentage) {
      let value = Math.floor(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      let progress = value > 100 ? 100 : value;
      dispatch(updatePercentage(imageName, progress));
    }

    switch (snapshot.state) {
      case firebase.storage.TaskState.PAUSED: // or 'paused'
        console.log('Upload is paused');
        break;
      case firebase.storage.TaskState.RUNNING: // or 'running'
        console.log('Upload is running');
        break;
      default:
        break;
    }
  };

const onError = (error: any) => {
  console.log(error);
};

const onSuccess = (image: any, dispatch: Dispatch) => {
  dispatch(removeImage(image));
  //  setTimeout(() => {
  //     dispatch(imageActions.deleteImage(image));
  //  }, 10000);
  // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
  //    console.log('File available at', downloadURL);
  // });
};

export { onStateChanged, onError, onSuccess };
