import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit'
import auth from './auth'
import async from './async'
import guidelines from './guidelines'
import image from './image'
import localUpload from './localUpload'
import navigation from './navigation'
import projects from './projects'
import ui from './ui'

const reducer = combineReducers({
  auth,
  async,
  guidelines,
  image,
  localUpload,
  navigation, // please check this file, i am not sure if it is correct
  projects,
  ui,
})

const middleware: Middleware = ({ getState, dispatch }) => {
  return next => action => {
    const returnValue = next(action)
    return returnValue
  }
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['async'],
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, middleware],
  devTools:
    process.env.REACT_APP_PROJECT_ID === 'co-one-app-1252e' ? false : true,
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
