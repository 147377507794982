import { createSlice } from '@reduxjs/toolkit';

export interface ImageState {
  images: any;
  reviewImageCount: any;
}

const initialState: ImageState = {
  images: {},
  reviewImageCount: 0,
};

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    incrementReviewImageCount: (state) => {
      state.reviewImageCount = state.reviewImageCount + 1;
    },
    addImage: (state, action) => {
      state.images = {
        ...state.images,
        [action.payload.image.pictureFile.name]: action.payload.image,
      };
    },
    removeImage: (state, action) => {
      const { [action.payload.name]: value, ...newImages } = state.images;
      state.images = newImages;
    },
    updateImage: (state, action) => {
      state.images = {
        ...state.images[action.payload.name],
        precentage: action.payload.precentage,
      };
    },
    clearImages: (state) => {
      state.images = {};
    },
  },
});

export const {
  incrementReviewImageCount,
  addImage,
  removeImage,
  updateImage,
  clearImages,
} = imageSlice.actions;

export default imageSlice.reducer;
