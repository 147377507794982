import { ButtonBase } from '@mui/material'
import React from 'react'
import styles from './NotFound.module.scss'
import { Button } from '@mui/joy'

type Params = {
  svg?: any
  title?: string
  desc?: string
  buttonText?: string
  onButtonClick?: any
  height?: number | string
  styles1?: any
}

const NotFound = ({
  svg,
  title,
  desc,
  buttonText,
  onButtonClick,
  height,
  styles1,
}: Params) => {
  return (
    <div className={styles.Container} style={{ height }}>
      <div className={styles.Content} style={styles1}>
        <div className={styles.InnerContainer}>
          <span className={styles.Title}>{title}</span>
          {svg ? (
            <img
              className={styles.ImageSvg}
              src={svg}
              alt="imageSvg"
              width={'80%'}
            />
          ) : null}
          <span>{desc}</span>
          {buttonText ? (
            <Button
              sx={{
                // width: '30%',
                color: '#fff',
                backgroundColor: '#3943b7',
                fontFamily: 'facto, sans-serif',
                borderColor: '#3943b7',
                width: '100%',

                '&:hover': {
                  backgroundColor: '#3943b7',
                  color: '#fff',

                  transition: 'all 0.3s ease-in-out',
                },
              }}
              onClick={onButtonClick}
              variant="solid"
            >
              {buttonText}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default NotFound
