import '../node_modules/vanilla-cookieconsent/dist/cookieconsent.js'
import '../node_modules/vanilla-cookieconsent/dist/cookieconsent.css'
import { useEffect } from 'react'

declare global {
  interface Window {
    initCookieConsent: any
  }
}

export default function Cookie() {
  useEffect(() => {
    const cc = window.initCookieConsent()
    if (cc) {
      cc.run({
        autorun: true,
        current_lang: 'en',
        gui_options: {},
        onAccept: () => console.log('ACCEPTED'),
        onChange: () => console.log('on change'),
        onFirstAction: function(user_preferences: any, cookie: any) {
          console.log('User accept type:', user_preferences.accept_type)
          console.log(
            'User accepted these categories',
            user_preferences.accepted_categories
          )
          console.log(
            'User reject these categories:',
            user_preferences.rejected_categories
          )
        },
        languages: {
          en: {
            consent_modal: {
              title: 'I use cookies',
              description:
                'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. ',
              primary_btn: {
                text: 'Accept',
                role: 'accept_all', // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Settings',
                role: 'settings', // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: 'Cookie preferences',
              save_settings_btn: 'Save settings',
              accept_all_btn: 'Accept all',

              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' },
                { col5: 'Type' },
              ],
              blocks: [
                {
                  title: 'Cookie usage',
                  description:
                    'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.',
                },
                {
                  title: 'Strictly necessary cookies',
                  description:
                    'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true,
                  },
                  cookie_table: [
                    {
                      col1: 'js.stripe.com',
                      col2: 'https://js.stripe.com',
                      col3: '',
                      col4:
                        'The data collected by these endpoints is designed to be secure and to not leave Stripe infrastructure. Access to this data is tightly controlled, and restricted to a small number of Stripe employees working on fraud prevention and security (and permissions are regularly reviewed).',
                      col5: 'Permanent cookie',
                      is_regex: true,
                    },
                    {
                      col1: 'm.stripe.network',
                      col2: 'https://m.stripe.network',
                      col3: '',
                      col4:
                        'The data collected by these endpoints is designed to be secure and to not leave Stripe infrastructure. Access to this data is tightly controlled, and restricted to a small number of Stripe employees working on fraud prevention and security (and permissions are regularly reviewed).',
                      col5: 'Permanent cookie',
                    },
                    {
                      col1: 'cdn.botpress.cloud',
                      col2: 'https://cdn.botpress.cloud/',
                      col3: '',
                      col4:
                        'The data collected by these endpoints is designed to be secure and to not leave Botpress infrastructure. Access to this data is tightly controlled, and restricted to a small number of Botpress employees working on fraud prevention and security (and permissions are regularly reviewed).',
                      col5: 'Permanent cookie',
                    },
                  ],
                },

                {
                  title: 'More information',
                  description:
                    'For any queries in relation to policy on cookies and your choices, please <a class="cc-link" href="/setting">contact us</a>.',
                },
              ],
            },
          },
        },
      })
    }
  }, [])
  return <div></div>
}
