import React, { useEffect } from 'react'
import {
  Route,
  Navigate,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'
import publicRoutes from './routes/PublicRoutes'
import privateRoutes from './routes/PrivateRoutes'
import { useSelector } from 'react-redux'
import { RootState } from './store'
import ROUTES from './routes'
import Layout from './components/Layout'
import ErrorPage from './components/Layout/ErrorPage'
import PublicRouteElement from './routes/PublicRoutes/PublicRouteElement'
import PrivateRouteElement from './routes/PrivateRoutes/PrivateRouteElement'

const ProtectedRoute = ({ user }: { user: any; redirectPath?: string }) => {
  if (!user) {
    return <Navigate to={ROUTES.SIGN_IN} replace />
  } else if (user && !user.emailVerified && user.accessToken) {
    return <Navigate to={ROUTES.EMAIL_VERIFICATION} replace />
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

const PublicRoute = ({ user }: { user: any }) => {
  const isSignInPage = window.location.pathname === ROUTES.SIGN_IN
  const isSignUpPage = window.location.pathname === ROUTES.SIGN_UP

  if (user && (isSignInPage || isSignUpPage)) {
    return <Navigate to={ROUTES.HOME_PAGE} replace />
  }

  return <Outlet />
}

const LoginRoute = ({
  user,
  redirectPath = '/',
}: {
  user: any
  redirectPath?: string
}) => {
  if (user) {
    if (user.emailVerified) {
      return <Navigate to={redirectPath} replace />
    }
  }

  return <Outlet />
}

function App() {
  const { user } = useSelector((state: RootState) => state.auth)

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          errorElement={<ErrorPage />}
          element={<PublicRoute user={user} />}
          // element={<LoginRoute user={user} />}
        >
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PublicRouteElement route={route} />}
            />
          ))}
        </Route>
        <Route element={<ProtectedRoute user={user} />}>
          {privateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRouteElement route={route} />}
            />
          ))}
        </Route>
      </>
    )
  )

  return <RouterProvider router={router} />
}

export default App
