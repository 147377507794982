import { createSlice } from "@reduxjs/toolkit";

export interface ProjectsState {
  projects: any;
}

const initialState: ProjectsState = {
  projects: [],
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = [...action.payload];
    },
    deleteProjectByName: (state, action) => {
      state.projects = state.projects.filter(
        (project: any) => project.projectName !== action.payload.projectName
      );
    },
    deleteProjectByID: (state, action) => {
      state.projects = state.projects.filter(
        (project: any) => project.projectID !== action.payload.projectID
      );
    },
    updateProject: (state, action) => {
      state.projects = [
        ...state.projects.filter(
          (project: any) =>
            project.projectID !== action.payload.project.projectID
        ),
        { ...action.payload.project },
      ];
    },
    addProject: (state, action) => {
      state.projects = [...state.projects, { ...action.payload.project }];
    },
  },
});

export const {
  setProjects,
  deleteProjectByName,
  deleteProjectByID,
  updateProject,
  addProject,
} = projectsSlice.actions;

export default projectsSlice.reducer;
