import { useNavigate } from 'react-router-dom'
import { Box, Button, Card, Typography } from '@mui/joy'
import checkYourEmail from '@/assets/images/icons/verification/check_your_email.svg'
import checkYourEmailMobile from '@/assets/images/icons/verification/mobile_check_your_email.svg'
import useWindowSize from '@/hooks/useWindowSize'
import ROUTES from '@/routes'

function SuccessSignup() {
  const navigate = useNavigate()
  const size = useWindowSize()

  return (
    <Card
      sx={{
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        height: '80%',
        margin: 'auto',
        padding: '1.5rem',
        borderRadius: '10px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      }}
    >
      <img
        src={
          size.width && size.width < 768 ? checkYourEmailMobile : checkYourEmail
        }
        alt="check your email"
      />
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: '500',
          color: '#000000',
          mt: '1rem',
        }}
      >
        Hello!
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '1rem',
          fontWeight: '400',
          color: '#868686',
        }}
      >
        An activation link was sent to your email, please click it to verify
        your account
      </Typography>
      <Box
        sx={{
          mt: '1rem',

          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // mb: "auto",
        }}
      >
        <Button
          size="lg"
          variant="solid"
          onClick={() => {
            navigate(ROUTES.SIGN_IN)
          }}
          sx={{
            borderRadius: '22px',

            color: '#fff',
            backgroundColor: '#000',
            borderColor: '#000',

            fontSize: '.8rem',
            border: '1px solid',
            '&:hover': {
              backgroundColor: '#000',
              color: 'white',
            },
          }}
          type="submit"
        >
          Sign In
        </Button>
      </Box>
    </Card>
  )
}

export default SuccessSignup
