import { createContext, useContext, useEffect, useState } from "react";

type Value = {
  isCollapse?: boolean;
  toggleIsCollapse: any;
};

const GlobalContext = createContext<Value>({ toggleIsCollapse: null });
export { GlobalContext };

export function useGlobal() {
  return useContext(GlobalContext);
}

const GlobalProvider = ({ children }: { children: any }) => {
  const [isCollapse, setIsCollapse] = useState(false);

  const value = {
    isCollapse,
    toggleIsCollapse: () => {
      setIsCollapse((prev) => !prev);
      const rawGlobalProvider: string | any =
        localStorage.getItem("globalProvider");
      const localGlobalProvider = JSON.parse(rawGlobalProvider);
      localStorage.setItem(
        "globalProvider",
        JSON.stringify({
          ...localGlobalProvider,
          isCollapse: !isCollapse,
        })
      );
    },
  };

  useEffect(() => {
    const rawGlobalProvider: string | any =
      localStorage.getItem("globalProvider");
    const localGlobalProvider = JSON.parse(rawGlobalProvider);
    if (localGlobalProvider) {
      const localIsCollapse = localGlobalProvider?.isCollapse;
      if (localIsCollapse) setIsCollapse(localIsCollapse);
    } else {
      const globalProviderJson: any = JSON.stringify(value);
      localStorage.setItem("globalProvider", globalProviderJson);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export default GlobalProvider;
