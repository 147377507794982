import React from 'react'
import styles from './freeTrail.module.scss'
import { Button, Divider, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import freeTrailPic from '../../assets/images/images/Payment/freeTrailPic.svg'
import { useEffect, useState } from 'react'
import { useFirebase } from '../../firebase'
import Swal from 'sweetalert2'
import PreviewIcon from '@mui/icons-material/Preview'
import InsightsIcon from '@mui/icons-material/Insights'
import TopicIcon from '@mui/icons-material/Topic'
import { styled } from '@mui/material/styles'
import {
  Box,
  ButtonBase,
  Collapse,
  MenuItem,
  FormControl,
  Select,
  IconButton,
} from '@mui/material'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useSelector, useDispatch } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Grid, Slide } from '@mui/material'
import { RootState } from '../../store'
import Checkout from '../Checkout'
import CheckIcon from '@mui/icons-material/Check'
import { dateToGoodFormat } from '../../helpers'
import useGetConfig from '../../hooks/useGetConfig'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { Stack } from '@mui/system'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import { Card, Modal } from '@mui/joy'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 43,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#449dd1',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#449dd1',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

function FreeTrail({ openModal, setOpenModal, customer }: any) {
  const { time } = useGetConfig()
  const ColorButton = styled(Button)(({ theme }: any) => ({
    ':disabled': {
      backgroundColor: '#449dd1',
      color: '#fff',
    },
    fontSize: '1rem',
    textTransform: 'none',
    backgroundColor: '#449dd1',
    '&:hover': {
      backgroundColor: '#3943b7',
    },
  }))

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }))

  const { subscriptionType } = useSelector(
    (state: RootState) => state.auth.customer
  )

  const [plans, setPlans] = useState<any>([])
  const [prices, setPrices] = useState<any>([])
  const [selectedPlanName, setSelectedPlanName] = useState(subscriptionType)
  const [features, setFeatures] = useState(false)
  const [annual, setAnnual] = useState(false)
  const [annotation, setAnnotation] = useState<string>('boundingBox')
  const [indxAnno, setIndxAnno] = useState(1000)
  const [unitPrice, setUnitPrice] = useState(0.06)
  const [progressValue, setProgressValue] = useState(0)

  const { api, functions } = useFirebase()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [checkoutPage, setCheckoutPage] = useState(false)
  // eslint-disable-next-line
  const [cardSuccess, setCardSuccess] = useState()
  // const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);

  useEffect(() => {
    const getPlan: any = {}
    api?.getAllDocsByPathAndQuery(`Plans`, 'name', 'asc').then((res: any) => {
      res.forEach((doc: any) => {
        getPlan[doc['name']] = ''
        api?.getAllDocsByPath(`Plans/${doc.id}/prices`).then((res2: any) => {
          res2.forEach((doc2: any) => {
            getPlan[doc['name']] = doc2.id
            setPlans(getPlan)
          })
        })
      })
    })
    api?.getAllDocsByPath('Prices').then((snapshot: any) => {
      snapshot.forEach((doc: any) => {
        setPrices(doc)
      })
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let progress = [92, 72, 52, 32]

    if (Object.keys(prices).length > 0) {
      if (indxAnno < 50000) {
        setUnitPrice(prices[annotation][0] / 1000)
        setProgressValue(progress[0])
      } else if (indxAnno >= 50000 && indxAnno < 100000) {
        setUnitPrice(prices[annotation][1] / 1000)
        setProgressValue(progress[1])
      } else if (indxAnno >= 100000 && indxAnno < 250000) {
        setUnitPrice(prices[annotation][2] / 1000)
        setProgressValue(progress[2])
      } else {
        setUnitPrice(prices[annotation][3] / 1000)
        setProgressValue(progress[3])
      }
    }
  }, [indxAnno, annotation, prices])

  const handleAnnotationSelect = (event: any) => {
    setAnnotation(event.target.value)
  }

  const handleIncrease = () => {
    setIndxAnno((prev) => {
      if (prev >= 20000 && prev < 250000) {
        return prev + 10000
      } else if (prev < 20000) {
        return prev + 1000
      } else {
        return prev
      }
    })
  }

  const handleDecrease = () => {
    setIndxAnno((prev) => {
      if (prev > 20000 && prev <= 250000) {
        return prev - 10000
      } else if (prev <= 20000) {
        return prev - 1000
      } else {
        return prev
      }
    })
  }

  useEffect(() => {
    cardSuccess === true &&
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Subscription Successful',
        showConfirmButton: false,
        timer: 1500,
      })
    cardSuccess === false &&
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Subscription Failed',
        showConfirmButton: false,
        timer: 1500,
      })
  }, [cardSuccess])

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
        }}
      >
        <Box>
          <Slide
            direction={'left'}
            in={checkoutPage}
            mountOnEnter
            unmountOnExit
            timeout={{
              enter: 1000,
              exit: 0,
            }}
          >
            <Box>
              <Checkout
                annual={annual}
                planName={selectedPlanName}
                setCheckoutPage={setCheckoutPage}
              />
            </Box>
          </Slide>

          {!checkoutPage && (
            <ScrollSync>
              <Slide
                direction={'right'}
                in={!checkoutPage}
                mountOnEnter
                unmountOnExit
                timeout={{
                  enter: 1000,
                  exit: 0,
                }}
              >
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#fff',
                    boxShadow: '5px 5px 15px 0 rgba(0, 0, 0, 0.16)',
                    border: 'none',
                    borderRadius: '10px',
                    height: '85vh',
                    pl: 6,
                    pr: 6,
                    width: '90%',
                    // make it center
                    margin: 'auto',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    <div
                      className={`${styles['billing-layout']}`}
                      // style={{ display: 'flex', flexWrap: 'wrap' }}
                    >
                      <div
                        style={{
                          width: '100%',
                          position: 'relative',
                        }}
                        className={`col-lg-12`}
                      >
                        <Box>
                          <div
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                            className="row"
                          >
                            <div
                              style={{
                                flex: '0 0 100%',
                                maxWidth: '100%',
                                width: '100%',
                                position: 'relative',
                              }}
                              className="col-12"
                            >
                              <div className={`${styles['entry-text3']}`}>
                                <p>Step 1/2</p>
                              </div>
                              <div
                                className={`${styles['entry-text']} text-center mt-4`}
                                style={{
                                  marginTop: '.5rem',
                                }}
                              >
                                <p>
                                  Choose a plan; Billing starts automatically
                                  after your a month trial period has expired
                                </p>
                              </div>
                            </div>
                          </div>
                          <div style={{ marginTop: '1.5rem' }}>
                            <ThemeProvider theme={theme}>
                              <Grid container spacing={3}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={8}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      border: ' solid 1px #cbcbcb',
                                      borderRadius: '10px',
                                      borderBottomLeftRadius: '0px',
                                      borderBottomRightRadius: '0px',
                                    }}
                                  >
                                    <div className={`${styles['plan-item1']}`}>
                                      <div
                                        className={`${styles['plan-item-header']}`}
                                      >
                                        Basic
                                      </div>
                                      <div
                                        className={`${styles['plan-item-description']}`}
                                      >
                                        Perfect plan for starters
                                      </div>
                                      <div
                                        className={`${styles['plan-item-price']}`}
                                      >
                                        <div
                                          style={{
                                            fontSize: '2.2rem',
                                            display: 'contents',
                                            color: '#292a29',
                                          }}
                                        >
                                          €{prices['Basic']}
                                        </div>
                                        /m
                                      </div>
                                      <div
                                        className={`${styles['plan-item-cost']}`}
                                      >
                                        <div>+</div>
                                        <div>Annotation Cost</div>
                                      </div>
                                      <Collapse in={!features} timeout={1000}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            color: '#292a29',
                                            fontSize: '1rem',
                                            marginTop: 20,
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: 5,
                                            }}
                                          >
                                            {' '}
                                            <TopicIcon /> Up to 1 projects{' '}
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: 5,
                                              marginTop: 10,
                                            }}
                                          >
                                            {' '}
                                            <PreviewIcon /> Tracking
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: 5,
                                              marginTop: 10,
                                              color: '#6b7ca4',
                                            }}
                                          >
                                            {' '}
                                            <InsightsIcon /> Analyzes
                                          </div>
                                        </div>
                                      </Collapse>
                                      <div
                                        className={`${styles['plan-item-button']}`}
                                      >
                                        <ColorButton
                                          disabled={
                                            subscriptionType === 'Basic'
                                          }
                                          variant="contained"
                                          sx={{
                                            backgroundColor:
                                              selectedPlanName === 'Basic'
                                                ? '#3943b7'
                                                : '',
                                            '&:hover': {
                                              backgroundColor:
                                                selectedPlanName === 'Basic'
                                                  ? '#899ed1'
                                                  : '',
                                            },
                                            fontFamily: 'facto, sans-serif',
                                          }}
                                          size="large"
                                          onClick={() => {
                                            if (selectedPlanName === 'Basic') {
                                              setCheckoutPage(true)
                                            }
                                            setSelectedPlanName('Basic')
                                          }}
                                        >
                                          {subscriptionType === 'Basic'
                                            ? 'Current Plan'
                                            : subscriptionType !== 'free' &&
                                              selectedPlanName !== 'Basic'
                                            ? 'Change Plan'
                                            : subscriptionType !== 'free' &&
                                              selectedPlanName === 'Basic'
                                            ? 'Start Free Trial'
                                            : selectedPlanName === 'Basic'
                                            ? 'Start Free Trial'
                                            : 'Select Plan'}
                                        </ColorButton>
                                      </div>
                                      <ScrollSyncPane>
                                        <Collapse
                                          in={features}
                                          timeout={1000}
                                          sx={{
                                            width: '100%',
                                            borderTop: '1px solid #cbcbcb',
                                            overflowY: 'scroll',
                                            maxHeight: '250px !important',
                                            '&::-webkit-scrollbar': {
                                              display: 'none',
                                            },
                                          }}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              1 project
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 65,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Tracking with time comparison,
                                              daily annotation numbers, class
                                              distribution charts
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Data management with local upload
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Standard metadata analysis
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Live review with view annotations,
                                              basic filtering
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Single user
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Customer success with chatbot
                                            </div>
                                          </Stack>
                                        </Collapse>
                                      </ScrollSyncPane>
                                    </div>
                                    <div className={`${styles['plan-item2']}`}>
                                      <div
                                        className={`${styles['plan-item-header']}`}
                                      >
                                        Professional
                                      </div>
                                      <div
                                        className={`${styles['plan-item-description']}`}
                                      >
                                        For users who want to do more
                                      </div>
                                      <div
                                        className={`${styles['plan-item-price']}`}
                                      >
                                        <div
                                          style={{
                                            fontSize: '2.2rem',
                                            display: 'contents',
                                            color: '#292a29',
                                          }}
                                        >
                                          €
                                          {annual
                                            ? 950
                                            : prices['Professional']}
                                        </div>
                                        {annual ? '/y ' : '/m '}
                                        <span
                                          style={{
                                            fontSize: '.7rem',
                                            color: '#3943b7',
                                          }}
                                        >
                                          + Annotation Cost
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          height: '2rem',
                                          display: 'flex',
                                          marginTop: '5px',
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          alignItems="center"
                                        >
                                          <Typography
                                            sx={{
                                              fontFamily: 'facto, sans-serif',
                                              fontSize: '0.8rem',
                                            }}
                                          >
                                            Monthly
                                          </Typography>

                                          {
                                            <IOSSwitch
                                              inputProps={{
                                                'aria-label': 'ant design',
                                              }}
                                              sx={{ m: 1 }}
                                              checked={annual}
                                              onClick={() => {
                                                setAnnual(!annual)
                                                if (!annual) {
                                                  setSelectedPlanName(
                                                    'Professional'
                                                  )
                                                } else {
                                                  setSelectedPlanName(
                                                    subscriptionType
                                                  )
                                                }
                                              }}
                                            />
                                          }
                                          <Typography
                                            sx={{
                                              fontFamily: 'facto, sans-serif',
                                              fontSize: '0.8rem',
                                            }}
                                          >
                                            Annual
                                          </Typography>
                                        </Stack>
                                      </div>
                                      <Collapse in={!features} timeout={1000}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            color: '#292a29',
                                            fontSize: '1rem',
                                            marginTop: 20,
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: 5,
                                            }}
                                          >
                                            {' '}
                                            <TopicIcon /> Up to 10 projects{' '}
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: 5,
                                              marginTop: 10,
                                            }}
                                          >
                                            {' '}
                                            <PreviewIcon /> Tracking
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: 5,
                                              marginTop: 10,
                                              color: '#6b7ca4',
                                            }}
                                          >
                                            {' '}
                                            <InsightsIcon /> Analyzes
                                          </div>
                                        </div>
                                      </Collapse>
                                      <div
                                        className={`${styles['plan-item-button']}`}
                                        style={{ marginTop: '10px' }}
                                      >
                                        <ColorButton
                                          disabled={
                                            subscriptionType === 'Professional'
                                          }
                                          variant="contained"
                                          sx={{
                                            backgroundColor:
                                              selectedPlanName ===
                                              'Professional'
                                                ? '#3943b7'
                                                : '',
                                            '&:hover': {
                                              backgroundColor:
                                                selectedPlanName ===
                                                'Professional'
                                                  ? '#899ed1'
                                                  : '',
                                            },
                                            fontFamily: 'facto, sans-serif',
                                          }}
                                          size="large"
                                          onClick={() => {
                                            if (
                                              selectedPlanName ===
                                              'Professional'
                                            ) {
                                              setCheckoutPage(true)
                                              return
                                            }
                                            setSelectedPlanName('Professional')
                                          }}
                                        >
                                          {subscriptionType === 'Professional'
                                            ? 'Current Plan'
                                            : subscriptionType !== 'free' &&
                                              selectedPlanName !==
                                                'Professional'
                                            ? 'Change Plan'
                                            : subscriptionType !== 'free' &&
                                              selectedPlanName ===
                                                'Professional'
                                            ? 'Start Free Trial'
                                            : selectedPlanName ===
                                              'Professional'
                                            ? 'Start Free Trial'
                                            : 'Select Plan'}
                                        </ColorButton>
                                      </div>
                                      <ScrollSyncPane>
                                        <Collapse
                                          in={features}
                                          timeout={1000}
                                          sx={{
                                            width: '100%',
                                            borderTop: '1px solid #cbcbcb',
                                            overflowY: 'scroll',
                                            maxHeight: '250px !important',
                                            '&::-webkit-scrollbar': {
                                              display: 'none',
                                            },
                                          }}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              10 projects
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 65,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Tracking with time comparison,
                                              daily annotation numbers, class
                                              distribution charts
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Data management with local upload,
                                              Amazon S3, Google Cloud
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Standard metadata analysis, Data
                                              Annotation, and Dataset
                                              Characteristics
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Live review with edit annotations,
                                              Advance Filtering
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Multi user management
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Customer support with chatbot,
                                              Dedicated Project Engineer
                                            </div>
                                          </Stack>
                                        </Collapse>
                                      </ScrollSyncPane>
                                    </div>
                                    <div className={`${styles['plan-item3']}`}>
                                      <div
                                        className={`${styles['plan-item-header']}`}
                                      >
                                        Enterprise
                                      </div>
                                      <div
                                        className={`${styles['plan-item-description']}`}
                                        style={{ fontSize: 13.5 }}
                                      >
                                        For businesses needing customization
                                      </div>
                                      <div
                                        className={`${styles['plan-item-price']}`}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '70%',
                                          height: '4.5rem',
                                          textAlign: 'center',
                                          color: 'rgb(41, 42, 41)',
                                        }}
                                      >
                                        Get in touch
                                      </div>
                                      <Collapse in={!features} timeout={1000}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            color: '#292a29',
                                            fontSize: '1rem',
                                            marginTop: 20,
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: 5,
                                            }}
                                          >
                                            {' '}
                                            <TopicIcon /> Custom project count{' '}
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: 5,
                                              marginTop: 10,
                                            }}
                                          >
                                            {' '}
                                            <PreviewIcon /> Tracking
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: 5,
                                              marginTop: 10,
                                            }}
                                          >
                                            {' '}
                                            <InsightsIcon /> Analyzes
                                          </div>
                                        </div>
                                      </Collapse>
                                      <div
                                        className={`${styles['plan-item-button']}`}
                                      >
                                        <ColorButton
                                          disabled={
                                            subscriptionType === 'Enterprise'
                                          }
                                          variant="contained"
                                          sx={{
                                            backgroundColor:
                                              selectedPlanName === 'Enterprise'
                                                ? '#6b7ca4'
                                                : '',
                                            '&:hover': {
                                              backgroundColor:
                                                selectedPlanName ===
                                                'Enterprise'
                                                  ? '#899ed1'
                                                  : '',
                                            },
                                            fontFamily: 'facto, sans-serif',
                                          }}
                                          size="large"
                                          onClick={() => {
                                            navigate('settings', {
                                              state: { from: 'ContactUs' },
                                            })
                                          }}
                                        >
                                          Contact Us
                                        </ColorButton>
                                      </div>
                                      <ScrollSyncPane>
                                        <Collapse
                                          in={features}
                                          timeout={1000}
                                          sx={{
                                            width: '100%',
                                            borderTop: '1px solid #cbcbcb',
                                            maxHeight: '250px !important',
                                            overflowY: 'scroll',
                                          }}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Custom project count
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 65,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Tracking with time comparison,
                                              daily annotation numbers, class
                                              distribution charts
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Data management with local upload,
                                              Amazon S3, Google Cloud, Custom
                                              Storage Integration
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Standard metadata analysis, Data
                                              Annotation, and Dataset
                                              Characteristics
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Live review with view annotations,
                                              Advance Filtering, and Custom
                                              Filtering Options
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Multi user management, Activity
                                              tracking, and Access control
                                            </div>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              width: '100%',
                                              justifyContent: 'space-between',
                                              padding: '0.4rem 0.6rem',
                                              height: 50,
                                              marginTop: 1,
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.featureContainer
                                              }
                                            >
                                              <CheckIcon
                                                className={styles.CheckIcon}
                                              />{' '}
                                              Customer support with chatbot,
                                              Dedicated Project Engineer
                                            </div>
                                          </Stack>
                                        </Collapse>
                                      </ScrollSyncPane>
                                    </div>
                                  </div>
                                  <ButtonBase
                                    sx={{
                                      display: 'flex',
                                      width: '100%',
                                      height: '50px',
                                      backgroundColor: '#fff',
                                      border: '1px solid #cbcbcb',
                                      borderBottomLeftRadius: features
                                        ? '0px'
                                        : '10px',
                                      borderBottomRightRadius: features
                                        ? '0px'
                                        : '10px',
                                      '&:focus': { outline: 'none' },
                                    }}
                                    onClick={() => {
                                      setFeatures(!features)
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        textAlign: 'center',
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        color: '#1d3557',
                                      }}
                                    >
                                      {' '}
                                      {features ? 'Less' : 'Features'}
                                    </Box>
                                  </ButtonBase>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={4}
                                >
                                  <div
                                    className={`${styles['plan-annotation-cost']}`}
                                  >
                                    <div
                                      className={`${styles['plan-annotation-cost-text']}`}
                                    >
                                      Annotation Cost
                                    </div>
                                    <FormControl
                                      variant="standard"
                                      sx={{ width: 140, margin: '10px 0px' }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={annotation}
                                        onChange={handleAnnotationSelect}
                                        label="Annotation"
                                        style={{ color: '#292a29' }}
                                      >
                                        <MenuItem value={'boundingBox'}>
                                          Bounding Box
                                        </MenuItem>
                                        <MenuItem value={'polygon'}>
                                          Polygon
                                        </MenuItem>
                                        <MenuItem value={'polyline'}>
                                          Polyline
                                        </MenuItem>
                                        <MenuItem value={'keypoint'}>
                                          Keypoint
                                        </MenuItem>
                                        <MenuItem value={'multiClassification'}>
                                          Multi Classification
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 10,
                                      }}
                                    >
                                      {indxAnno <= 1000 ? (
                                        <IconButton
                                          disabled
                                          aria-label="minus"
                                          onClick={() => handleDecrease()}
                                          sx={{
                                            marginTop: '5px',
                                            '&:focus': { outline: 'none' },
                                          }}
                                        >
                                          <RemoveCircleRoundedIcon
                                            style={{ color: '#888' }}
                                          />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          aria-label="minus"
                                          onClick={() => handleDecrease()}
                                          sx={{
                                            marginTop: '5px',
                                            '&:focus': { outline: 'none' },
                                          }}
                                        >
                                          <RemoveCircleRoundedIcon
                                            style={{ color: '#449dd1' }}
                                          />
                                        </IconButton>
                                      )}
                                      <div
                                        style={{
                                          color: '#292a29',
                                          fontSize: '2.5rem',
                                          margin: '0px 5px',
                                        }}
                                      >
                                        {indxAnno}
                                      </div>
                                      {indxAnno >= 250000 ? (
                                        <IconButton
                                          disabled
                                          aria-label="plus"
                                          onClick={() => handleIncrease()}
                                          sx={{
                                            marginTop: '5px',
                                            '&:focus': { outline: 'none' },
                                          }}
                                        >
                                          <AddCircleRoundedIcon
                                            style={{ color: '#888' }}
                                          />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          aria-label="plus"
                                          onClick={() => handleIncrease()}
                                          sx={{
                                            marginTop: '5px',
                                            '&:focus': { outline: 'none' },
                                          }}
                                        >
                                          <AddCircleRoundedIcon
                                            style={{ color: '#449dd1' }}
                                          />
                                        </IconButton>
                                      )}
                                    </div>
                                    <div
                                      className="d-flex flex-column justify-content-center align-items-center"
                                      style={{
                                        textAlign: 'center',
                                      }}
                                    >
                                      <div
                                        style={{
                                          color: '#292a29',
                                          fontSize: '0.8rem',
                                        }}
                                      >
                                        annotations
                                      </div>
                                      <div
                                        style={{
                                          color: '#bdc6ce',
                                          fontSize: '0.6rem',
                                        }}
                                      >
                                        for a month
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: '1.2rem',
                                        color: '#bdc6ce',
                                        marginTop: 40,
                                      }}
                                    >
                                      Unit Price
                                    </div>
                                    <div
                                      style={{
                                        position: 'relative',
                                        marginTop: 10,
                                      }}
                                    >
                                      <BorderLinearProgress
                                        variant="determinate"
                                        value={progressValue}
                                      />
                                      <div
                                        style={{
                                          position: 'absolute',
                                          width: '100%',
                                          top: -50,
                                          left: -55,
                                          transition: 'all 1.5s ease-in-out',
                                          transform:
                                            'translateX(' +
                                            progressValue +
                                            '%)',
                                        }}
                                      >
                                        <div className={`${styles['bubble']}`}>
                                          €
                                          {unitPrice.toString().length > 6
                                            ? unitPrice.toFixed(4)
                                            : unitPrice}
                                        </div>
                                        <div
                                          className={`${styles['triangle']}`}
                                        ></div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: '1.2rem',
                                        color: '#bdc6ce',
                                        marginTop: 20,
                                      }}
                                    >
                                      Pricing Summary <InfoOutlinedIcon />
                                    </div>
                                    <div
                                      className={`${styles['plan-item-price']}`}
                                      style={{
                                        marginTop: 0,
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: '2rem',
                                          color: '#292a29',
                                          display: 'contents',
                                        }}
                                      >
                                        €
                                        {selectedPlanName === 'Professional'
                                          ? (annual
                                              ? 950 + indxAnno * unitPrice
                                              : 99 + indxAnno * unitPrice
                                            ).toFixed(2)
                                          : selectedPlanName === 'Enterprise'
                                          ? (0 + indxAnno * unitPrice).toFixed(
                                              2
                                            )
                                          : (0 + indxAnno * unitPrice).toFixed(
                                              2
                                            )}
                                      </div>
                                      {annual ? '/year' : '/month'}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </ThemeProvider>
                          </div>
                          <Collapse in={!features} timeout={1000}>
                            <div
                              style={{ display: 'flex', flexWrap: 'wrap' }}
                              className="row"
                            >
                              <div
                                style={{
                                  flex: '0 0 100%',
                                  maxWidth: '100%',
                                  width: '100%',
                                  position: 'relative',
                                }}
                                className="col-12"
                              >
                                <div
                                  className={`${styles['entry-text2']}`}
                                  style={{ marginTop: '1rem' }}
                                >
                                  <p>Free trial terms</p>
                                </div>
                                <Divider
                                  sx={{
                                    marginTop: '5px',
                                  }}
                                />
                                <div
                                  className={`${styles['entry-text4']} text-center mt-4`}
                                  style={{
                                    marginTop: '.5rem',
                                    marginBottom: '1rem',
                                  }}
                                >
                                  <p>
                                    {
                                      <CheckIcon
                                        sx={{
                                          paddingTop: '5px',
                                        }}
                                      />
                                    }
                                    Billing starts automatically after your free
                                    trial period has expired.
                                  </p>
                                  <p>
                                    {
                                      <CheckIcon
                                        sx={{
                                          paddingTop: '5px',
                                        }}
                                      />
                                    }
                                    If you do not want the invoice to be issued,
                                    cancel the membership before{' '}
                                    {dateToGoodFormat(time + 2592000000)}.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </Box>
                      </div>
                    </div>
                  </div>
                </Card>
              </Slide>
            </ScrollSync>
          )}
        </Box>
      </Modal>
    </>
  )
}

export default FreeTrail
