import React from "react";

const ErrorPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <span
        style={{
          fontSize: "300px",
          fontWeight: "bold",
          color: "#707070",
        }}
      >
        404
      </span>
      <span
        style={{
          fontSize: "100px",
          fontWeight: "bold",
          color: "#707070",
        }}
      >
        Not Found
      </span>
    </div>
  );
};

export default ErrorPage;
